.container {
  padding-top: 60px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  background-color: #eee;

  .content {
    justify-content: center;
    align-items: center;
    background-color: #eee;
    padding: 15px;
    height: 100vh;
    margin: auto;

    .image_container {
      margin: auto auto 25px auto;
      width: 300px;
    }

    .text {
      text-align: center;

      .page_heading {
        color: #24a9a7;
        font-size: 36px;
        line-height: 1.16667;
        font-weight: 600;
        margin: 0;
      }

      p {
        font-size: 15px;
        margin: 0 0 0.75em;
      }
    }

    .more {
      .links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      .links_item {
        display: block;
        position: relative;

        .links_btn {
          cursor: pointer;
          display: block;
          font-size: 15px;
          line-height: 1.46667;
          padding: 10px;
          color: #24a9a7;
          text-decoration: none;
          transition: color 0.1s linear;
        }

        .links_btn:hover {
          color: rgb(23, 106, 105);
        }

        button {
          border: none;
          outline: none;
          appearance: none;
          text-align: left;
          white-space: normal;
        }
      }

      .links_item:not(:last-child)::after {
        position: absolute;
        top: 15px;
        right: 0;
        background-color: rgba(0, 0, 0, 0.133333);
        content: "";
        display: block;
        height: 12px;
        width: 1px;
      }
    }
  }
}
